import Vue from 'vue';
import Vuex from 'vuex';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";

Vue.use(Vuex)

const app = initializeApp({
    apiKey: "AIzaSyB_FPARAK3iq2l6aydh7bIlgrdjZzP7uN0",
    authDomain: "csf-clinic.firebaseapp.com",
    projectId: "csf-clinic",
    storageBucket: "csf-clinic.appspot.com",
    messagingSenderId: "981127696406",
    appId: "1:981127696406:web:9c6473a7c3cec950c4e537"
});

const Storage = getStorage();
const StorageListRef = ref(Storage, '/');
const DB = getFirestore(app);

function getColectionFromDatabase(c) {
	return getDocs(collection(DB, c));
}
function getDocFromDatabase(c, d) {
	return getDoc(doc(DB, c, d));
}
function setLS(key, value) {
	localStorage.setItem(key, JSON.stringify(value));
}
function getLS(key) {
	return JSON.parse(localStorage.getItem(key));
}
// function removeFromLs(key) {
// 	localStorage.removeItem(key);
// }

export default new Vuex.Store({
    namespaced: true,
    state: {
        selectedLang: 'ua',
        categories: [],
        services: [],
        imageStorage: [],
        metaInfo: {},
        pagesContent: {
            home: {},
            about: {},
            services: {},
            sales: {},
            team: {},
            reviews: {},
            contacts: {},
            prices: {},
            credits: {},
            general: {}
        }
    },
    getters: {
        getMetaInfo(state) {
            return state.metaInfo;
        },
        getRewardsContent(state) {
            return state.pagesContent.about.rewards;
        },
        getImageStorage(state) {
            return state.imageStorage;
        },
        getSelectedLang(state) {
            return state.selectedLang;
        },
        getCategories(state) {
            return state.categories;
        },
        getServices(state) {
            return state.services;
        },
        getHomePageContent(state) {
            return state.pagesContent.home;
        },
        getAboutPageContent(state) {
            return state.pagesContent.about;
        },
        getPricesPageContent(state) {
            return state.pagesContent.prices;
        },
        getServicesPageContent(state) {
            return state.pagesContent.services;
        },
        getTeamPageContent(state) {
            return state.pagesContent.team;
        },
        getCreditsPageContent(state) {
            return state.pagesContent.credits;
        },
        getReviewsPageContent(state) {
            return state.pagesContent.reviews;
        },
        getSalesPageContent(state) {
            return state.pagesContent.sales;
        },
        getContactsPageContent(state) {
            return state.pagesContent.contacts;
        },
        getGeneralPageContent(state) {
            return state.pagesContent.general;
        },
        getCategoryById: (state) => (id) => {
            return state.categories.filter((el) => {
                return (el.id == id);
            })[0];
        },
        getServicesByCategoryId: (state) => (id) => {
            return state.services.filter((el) => {
                return (el.category == id);
            });
        },
        getServiceById: (state) => (id) => {
            return state.services.filter((el) => {
                return (el.code == id);
            })[0];
        }
        // isProductInCart: (state) => (productId) => {
        //     return state.productsInCart.some((product) => {
        //         return (product.code == productId);
        //     });
        // }
    },
    mutations: {
    },
    actions: {
        setLang(context, lang) {
            context.state.selectedLang = lang;
            localStorage.setItem('lang', lang);
        },
        sendOrder(context, order) {
            return setDoc(doc(DB, 'Orders', order.docName), order);
        },
        fetchCategories(context) {
			getColectionFromDatabase('Categories').then((data) => {
				data.forEach((element) => {
					context.state.categories.push(element.data());
				})
			});
		},
        fetchServices(context) {
			getColectionFromDatabase('Services').then((data) => {
				data.forEach((element) => {
					context.state.isCatalogLoading = false;
					context.state.services.push(element.data());
				})
			});
		},
        fetchStaticContent(context) {
			getDocFromDatabase('Pages', 'Home').then((data) => {
				context.state.pagesContent.home = data.data();
			});
			getDocFromDatabase('Pages', 'Services').then((data) => {
				context.state.pagesContent.services = data.data();
			});
			getDocFromDatabase('Pages', 'About').then((data) => {
				context.state.pagesContent.about = data.data();
			});
			getDocFromDatabase('Pages', 'Team').then((data) => {
				context.state.pagesContent.team = data.data();
			});
			getDocFromDatabase('Pages', 'Contacts').then((data) => {
				context.state.pagesContent.contacts = data.data();
			});
			getDocFromDatabase('Pages', 'Sales').then((data) => {
				context.state.pagesContent.sales = data.data();
			});
			getDocFromDatabase('Pages', 'Reviews').then((data) => {
				context.state.pagesContent.reviews = data.data();
			});
			getDocFromDatabase('Pages', 'Prices').then((data) => {
				context.state.pagesContent.prices = data.data();
			});
			getDocFromDatabase('Pages', 'Credits').then((data) => {
				context.state.pagesContent.credits = data.data();
			});
			getDocFromDatabase('Pages', 'GeneralInfo').then((data) => {
				context.state.pagesContent.general = data.data();
			});
		},
        fetchMetaInfo(context) {
            getDocFromDatabase('SEO', 'Meta').then((data) => {
				context.state.metaInfo = data.data();
			});
        },
        fetchImageStorage(context) {
            listAll(StorageListRef)
                .then((res) => {
                    let storage = new Array(res.prefixes.length + 1);
                    let i = -1;
                    res.prefixes.forEach(prefixe => {
                        i++;
                        storage[i] = {
                            name: prefixe.name,
                            list: []
                        }
                        listAll(prefixe)
                            .then(prefixeRes => {
                                prefixeRes.items.forEach(image => {
                                    getDownloadURL(image)
                                        .then(url => {
                                            storage[i].list.push(url);
                                        });
                                });
                            })
                    });
                    storage[i+1] = {
                        name: 'Other',
                        list: []
                    }
                    res.items.forEach((image) => {
                        getDownloadURL(image)
                            .then(url => {
                                storage[i + 1].list.push(url);
                            });
                    });
                    context.state.imageStorage = storage;
                });
        }
    },
    modules: {
    }
});
